import * as React from 'react'
import injectSheet from 'react-jss'

import ExpansionPanel from './ExpansionPanel'

interface FAQ {
  question: string
  answerNode: {
    childMarkdownRemark: {
      html: string
    }
  }
}

interface ExpansionPanelContainerProps {
  classes?: any
  items: FAQ[]
  sectionTitle?: string
}

class ExpansionPanelContainer extends React.Component<ExpansionPanelContainerProps, {}> {
  render() {
    const { classes: c, items, sectionTitle } = this.props
    return (
      <div className={c.expansionPanelContainer}>
        {!!sectionTitle && <h1 className={c.expansionPanelContainerTitle}>{sectionTitle}</h1>}
        {items.map(item => {
          return (
            <ExpansionPanel
              key={item.question}
              title={item.question}
              content={item.answerNode.childMarkdownRemark.html}
            />
          )
        })}
      </div>
    )
  }
}

const styles = {
  expansionPanelContainer: {
    height: 'auto',
    '&:first-child': {
      '& > h1': {
        marginTop: '0',
      },
    },
  },
  expansionPanelContainerTitle: {
    fontSize: '2rem',
    fontWeight: '300',
  },
}

export default injectSheet(styles)(ExpansionPanelContainer)
