import * as React from "react";
import { Component } from "react";

const styles = {
  minWidth: "320px",
  height: "580px"
};

class CalendlyInline extends React.Component {
  componentDidMount() {
    const calendlyScript = document.createElement("script");
    calendlyScript.type = "text/javascript";
    calendlyScript.src =
      "https://assets.calendly.com/assets/external/widget.js";

    document.head.appendChild(calendlyScript);
  }

  render() {
    return (
      <div
        className="calendly-inline-widget"
        data-url="https://calendly.com/book-demo-hq/15"
        style={styles}
      />
    );
  }
}

export default CalendlyInline;
